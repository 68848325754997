@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300i,400,400i,700,700i&display=swap");
*:not(td), *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-size: 62.5%;
  box-sizing: border-box;
}

body {
  font-size: 1.6rem;
  color: #04092E;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #d6001c;
  transition: 0.1s linear;
}
a:hover {
  color: #a30015;
}

p {
  margin: 2rem 0;
  line-height: 1.4em;
}
p:last-of-type {
  margin-bottom: 0;
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 70rem;
}

.vapid-px40 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.vapid-px60 {
  padding-right: 60px !important;
  padding-left: 60px !important;
}

.vapid-px75 {
  padding-right: 75px !important;
  padding-left: 75px !important;
}

.button {
  display: inline-block;
  padding: 1.2rem 3.3rem;
  font-weight: 800;
  text-transform: uppercase;
  text-decoration: none;
  color: #04092E;
  background: #FFC9E2;
  border-radius: 4px;
  cursor: pointer;
}
.button.login {
  margin-top: 2rem;
}
.button:hover {
  color: white;
  background-color: #ff96c7;
}

.header {
  padding: 6rem 2rem;
  flex-direction: column;
  justify-content: center;
  color: white;
  background: #04092E;
}
@media screen and (min-width: 960px) {
  .header {
    display: flex;
    min-height: 100vh;
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}
.header h1, .header h2 {
  margin-bottom: 4rem;
  max-width: 100%;
  line-height: 1.1em;
  text-transform: uppercase;
}
.header h1 {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  font-family: "Monoton", cursive;
  font-size: 6rem;
  font-weight: normal;
  width: 1350px;
  color: #FFC9E2;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 960px) {
  .header h1 {
    font-size: 10rem;
  }
}
.header h2 {
  font-size: 1.8rem;
  font-weight: 800;
  letter-spacing: 0.4rem;
}
@media screen and (min-width: 960px) {
  .header h2 {
    font-size: 3rem;
  }
}
.header div {
  font-size: 1.6rem;
  line-height: 1.4em;
}
@media screen and (min-width: 960px) {
  .header div {
    font-size: 1.8rem;
  }
}
.header small {
  display: block;
  margin-top: 4rem;
}

section {
  padding: 4rem;
}
section header {
  margin-bottom: 4rem;
}
section a {
  color: #ff63ab;
}
section a:hover {
  color: #ff3090;
}

form {
  text-align: left;
  font-size: 1.8rem;
}
form .field {
  margin-bottom: 2rem;
}
form label {
  display: block;
  font-weight: 800;
  margin-bottom: 0.5rem;
}
form input,
form textarea {
  font-family: inherit;
  font-size: inherit;
}
form input:not([type=submit]),
form textarea {
  width: 100%;
  padding: 1rem;
  color: #04092E;
  border: 1px solid #04092E;
  outline: 0;
}
form textarea {
  height: 12rem;
}

footer {
  padding: 2rem;
}
footer img {
  max-width: 10rem;
}

/* begin custom styles */
.vapid-px30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.vapid-px40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.vapid-px60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

#main {
  background: #fff;
  width: 100%;
  height: 100%;
  margin: 100px 0 0;
}
#main.child-page {
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(234, 234, 234) 15%);
}

.email-info {
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  text-align: center;
  background: #ffffff;
  display: block;
  max-width: 600px;
  margin: 0 auto 40px;
  padding: 40px;
  color: #193C56;
  font-size: 15px !important;
}
.email-info.main {
  background: none;
  padding: 100px 0 20px;
}
.email-info.main h3 {
  text-transform: uppercase;
  color: #888888;
  padding: 5px 0 0;
}
.email-info ul, .email-info li, .email-info p {
  color: #193C56 !important;
  text-align: center !important;
  display: block !important;
}
.email-info li, .email-info p {
  border-bottom: 1px solid #dedede;
  padding: 20px 0 !important;
}
.email-info p:first-child {
  padding-top: 0 !important;
}
.email-info span {
  display: block;
  padding-top: 20px;
}
.email-info p span {
  padding: 0;
}
.email-info strong {
  display: block;
  width: 100%;
  background: #193C56;
  color: #fff;
  padding: 20px;
  text-align: center;
  text-transform: uppercase;
}

.zIndex {
  z-index: -1 !important;
}

#logobar {
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  width: 100%;
  text-align: center;
  padding: 20px 0;
  background: #ffffff;
  position: fixed;
  top: 0;
  z-index: 0;
}
#logobar img {
  left: 10px;
  position: relative;
}
#logobar ul, #logobar li {
  list-style: none;
}
#logobar h3 {
  margin: -21px 0 0;
  text-transform: uppercase;
  color: #bcbcbc;
  font-size: 16px;
  font-weight: bold;
}

#menu {
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  width: 100%;
  text-align: center;
  padding: 23px 0;
  background-attachment: fixed;
  position: absolute;
  top: 85px;
  text-transform: uppercase;
  font-size: 15px;
  background: #193C56;
}
#menu ul, #menu li {
  list-style: none;
  text-align: center;
}
#menu li {
  display: inline;
  padding: 0 0 0 30px;
}
#menu li:first-child {
  padding: 0;
}
#menu a {
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  font-size: 15px;
  opacity: 1;
  transition: opacity 0.15s ease-in-out;
  -moz-transition: opacity 0.15s ease-in-out;
  -webkit-transition: opacity 0.15s ease-in-out;
}
#menu a:hover {
  opacity: 0.7;
}

.cb #menu {
  background: rgb(214, 0, 28);
  background: linear-gradient(195deg, rgb(214, 0, 28) 0%, rgb(25, 60, 86) 35%);
}

.vetshows #menu {
  background: rgb(214, 0, 28);
  background: linear-gradient(195deg, rgb(234, 6, 140) 0%, rgb(25, 60, 86) 35%);
}

.pvd #menu {
  background: rgb(214, 0, 28);
  background: linear-gradient(195deg, rgb(15, 123, 182) 0%, rgb(25, 60, 86) 35%);
}

.fixed {
  position: fixed !important;
  top: 0 !important;
}

#menu-spacer {
  margin: 160px 0 0 0;
  background: #ffffff;
  width: 100%;
}

#container {
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  text-align: left;
  width: 100%;
  max-width: 800px;
  margin: 0 auto 40px;
  padding: 0 40px;
}

#email-code {
  height: 500px;
  width: 600px;
  margin: 20px auto 50px;
  padding: 40px;
  background: #ffffff;
}
#email-code textarea {
  height: 100%;
  width: 100%;
  border: none;
}

h2 {
  font-size: 22px;
  padding: 0;
}

h3 {
  font-size: 18px;
  font-weight: normal;
  padding: 30px 0 0;
}

ol {
  padding: 20px 0 0 20px;
}

li {
  font-size: 16px;
  color: #777777;
  font-weight: normal;
  line-height: 22px;
}

.email-menu-header {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 3px solid #193C56;
  padding: 10px 0;
}
.email-menu-header h3 {
  padding: 0 10px 0 0;
  color: #193C56;
  font-weight: bold;
}
.email-menu-header a i {
  font-size: 30px;
}

a.new-email i {
  transition: all 0.2s ease-in-out;
}
a.new-email i:hover {
  transform: scale(1.12);
}

/*

#text-area-button {
  text-decoration: none;
  cursor: pointer;
  background: #193C56;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  padding: 5px 15px;
  text-align: center;
  border-radius: 15px;
  margin: 0 auto 50px;
  width: 200px;

  a {
    color: #fff;
    text-decoration: none;
  }

  &:hover {
    background: #d6001c;
    color: #ffffff;
  }

  i {
    margin: -1px 0 0;
  }
}

*/
.email-menu {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  border-bottom: 1px solid #dedede;
  padding: 20px 0;
}
.email-menu li a:not(.text) {
  text-decoration: none;
  cursor: pointer;
  background: #193C56;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  padding: 5px 15px;
  text-align: center;
  border-radius: 15px;
  margin: 0 0 0 5px;
}
.email-menu li a:not(.text):hover {
  background: #d6001c;
  color: #ffffff;
}
.email-menu li a:not(.text) i {
  margin: -1px 0 0;
}
.email-menu li a.show-all {
  font-weight: bold;
  color: #d6001c;
  background: #fff;
  padding: 0;
  display: flex;
}
.email-menu li a.show-all:hover {
  color: #0e202e;
  background: #fff;
}
.email-menu span {
  padding: 0 30px 0 0;
}

p {
  line-height: inherit;
}

/* end custom styles */
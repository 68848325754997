@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300i,400,400i,700,700i&display=swap');

*:not(td), *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-family: 'Source Sans Pro', Helvetica, sans-serif;
  font-size: 62.5%;
  box-sizing: border-box;
}

body {
  font-size: 1.6rem;
  color: #04092E;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #d6001c;
  transition: 0.1s linear;

  &:hover {
    color: darken(#d6001c, 10%);
  }
}

p {
  margin: 2rem 0;
  line-height: 1.4em;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 70rem;
}

.vapid-px40 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.vapid-px60 {
  padding-right: 60px !important;
  padding-left: 60px !important;
}

.vapid-px75 {
  padding-right: 75px !important;
  padding-left: 75px !important;
}

.button {
  display: inline-block;
  padding: 1.2rem 3.3rem;
  font-weight: 800;
  text-transform: uppercase;
  text-decoration: none;
  color: #04092E;
  background: #FFC9E2;
  border-radius: 4px;
  cursor: pointer;

  &.login {
    margin-top: 2rem;
  }

  &:hover {
    color: white;
    background-color: darken(#FFC9E2, 10%);
  }
}

.header {
  padding: 6rem 2rem;
  flex-direction: column;
  justify-content: center;
  color: white;
  background: #04092E;

  @media screen and (min-width: 960px) {
    display: flex;
    min-height: 100vh;
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  h1, h2 {
    margin-bottom: 4rem;
    max-width: 100%;
    line-height: 1.1em;
    text-transform: uppercase;
  }

  h1 {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    font-family: 'Monoton', cursive;
    font-size: 6rem;
    font-weight: normal;
    width: 1350px;
    color: #FFC9E2;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 960px) {
      font-size: 10rem;
    }
  }

  h2 {
    font-size: 1.8rem;
    font-weight: 800;
    letter-spacing: 0.4rem;

    @media screen and (min-width: 960px) {
      font-size: 3rem;
    }
  }

  div {
    font-size: 1.6rem;
    line-height: 1.4em;

    @media screen and (min-width: 960px) {
      font-size: 1.8rem;
    }
  }

  small {
    display: block;
    margin-top: 4rem;
  }
}

section {
  padding: 4rem;

  header {
    margin-bottom: 4rem;
  }

  a {
    color: darken(#FFC9E2, 20%);

    &:hover {
      color: darken(#FFC9E2, 30%);
    }
  }
}

form {
  text-align: left;
  font-size: 1.8rem;

  .field {
    margin-bottom: 2rem;
  }

  label {
    display: block;
    font-weight: 800;
    margin-bottom: 0.5rem;
  }

  input,
  textarea {
    font-family: inherit;
    font-size: inherit;
  }

  input:not([type=submit]),
  textarea {
    width: 100%;
    padding: 1rem;
    color: #04092E;
    border: 1px solid #04092E;
    outline: 0;
  }

  textarea {
    height: 12rem;
  }
}

footer {
  padding: 2rem;

  img {
    max-width: 10rem;
  }
}

/* begin custom styles */

.vapid-px30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.vapid-px40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.vapid-px60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

#main {
  background: #fff;
  width: 100%;
  height: 100%;
  margin: 100px 0 0;

  &.child-page {
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(234,234,234,1) 15%);
  }
}

.email-info {
  font-family: 'Source Sans Pro', Helvetica, sans-serif;
  text-align: center;
  background: #ffffff;
  display: block;
  max-width: 600px;
  margin: 0 auto 40px;
  padding: 40px;
  color: #193C56;
  font-size: 15px !important;

  &.main {
    background: none;
    padding: 100px 0 20px;
  }

  &.main h3 {
    text-transform: uppercase;
    color: #888888;
    padding: 5px 0 0;
  }

  ul, li, p {
    color: #193C56 !important;
    text-align: center !important;
    display: block !important;
  }

  li, p {
    border-bottom: 1px solid #dedede;
    padding: 20px 0 !important;
  }

  p:first-child {
    padding-top: 0 !important;
  }

  span {
    display: block;
    padding-top: 20px;
  }

  p span {
    padding: 0;
  }

  strong {
    display: block;
    width: 100%;
    background: #193C56;
    color: #fff;
    padding: 20px;
    text-align: center;
    text-transform: uppercase;
  }

}

.zIndex {
  z-index: -1 !important;
}

#logobar {
  font-family: 'Source Sans Pro', Helvetica, sans-serif;
  width: 100%;
  text-align: center;
  padding: 20px 0;
  background: #ffffff;
  position: fixed;
  top: 0;
  z-index: 0;

  img {
    left: 10px;
    position: relative;
  }

  ul, li {
    list-style: none;
  }

  h3 {
    margin: -21px 0 0;
    text-transform: uppercase;
    color: #bcbcbc;
    font-size: 16px;
    font-weight: bold;
  }
}





#menu {
  font-family: 'Source Sans Pro', Helvetica, sans-serif;
  width: 100%;
  text-align: center;
  padding: 23px 0;
  background-attachment: fixed;
  position: absolute;
  top: 85px;
  text-transform: uppercase;
  font-size: 15px;
  background: #193C56;

  ul, li {
    list-style: none;
    text-align: center;
  }

  li {
    display: inline;
    padding: 0 0 0 30px;

    &:first-child {
      padding: 0;
    }
  }

  a {
    color: #ffffff;
    font-weight: bold;
    text-decoration: none;
    font-size: 15px;
    opacity: 1;
     transition: opacity .15s ease-in-out;
     -moz-transition: opacity .15s ease-in-out;
     -webkit-transition: opacity .15s ease-in-out;

    &:hover {
      opacity: .7;
    }
  }
}

.cb #menu {
  background: rgb(214,0,28);
  background: linear-gradient(195deg, rgba(214,0,28,1) 0%, rgba(25,60,86,1) 35%);
}

.vetshows #menu {
  background: rgb(214,0,28);
  background: linear-gradient(195deg, rgba(234,6,140,1) 0%, rgba(25,60,86,1) 35%);
}

.pvd #menu {
  background: rgb(214,0,28);
  background: linear-gradient(195deg, rgba(15,123,182,1) 0%, rgba(25,60,86,1) 35%);
}

.fixed {
  position: fixed !important;
  top: 0 !important;
}

#menu-spacer {
  margin: 160px 0 0 0;
  background: #ffffff;
  width: 100%;
}

#container {
  font-family: 'Source Sans Pro', Helvetica, sans-serif;
  text-align: left;
  width: 100%;
  max-width: 800px;
  margin: 0 auto 40px;
  padding: 0 40px;
}

#email-code {
  height: 500px;
  width: 600px;
  margin: 20px auto 50px;
  padding: 40px;
  background: #ffffff;

  textarea {
    height: 100%;
    width: 100%;
    border: none;
  }
}

h2 {
  font-size: 22px;
  padding: 0;
}

h3 {
  font-size: 18px;
  font-weight: normal;
  padding: 30px 0 0;
}

ol {
  padding: 20px 0 0 20px;
}

li {
  font-size: 16px;
  color: #777777;
  font-weight: normal;
  line-height: 22px;
}

.email-menu-header {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 3px solid #193C56;
  padding: 10px 0;

  h3 {
    padding: 0 10px 0 0;
    color: #193C56;
    font-weight: bold;
  }

  a i {
    font-size: 30px;
  }

}

a.new-email i {
  transition: all .2s ease-in-out;
  &:hover {
    transform: scale(1.12);
  }
}

/*

#text-area-button {
  text-decoration: none;
  cursor: pointer;
  background: #193C56;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  padding: 5px 15px;
  text-align: center;
  border-radius: 15px;
  margin: 0 auto 50px;
  width: 200px;

  a {
    color: #fff;
    text-decoration: none;
  }

  &:hover {
    background: #d6001c;
    color: #ffffff;
  }

  i {
    margin: -1px 0 0;
  }
}

*/

.email-menu {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  border-bottom: 1px solid #dedede;
  padding: 20px 0;

  li a:not(.text) {
    text-decoration: none;
    cursor: pointer;
    background: #193C56;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 13px;
    padding: 5px 15px;
    text-align: center;
    border-radius: 15px;
    margin: 0 0 0 5px;

    &:hover {
      background: #d6001c;
      color: #ffffff;
    }

    i {
      margin: -1px 0 0;
    }
  }

  li a.show-all {
    font-weight: bold;
    color: #d6001c;
    background: #fff;
    padding: 0;
    display: flex;

    &:hover {
      color: darken(#193C56, 10%);
      background: #fff;
    }
  }

  span {
    padding: 0 30px 0 0;
  }
}

p {
  line-height: inherit;
}

/* end custom styles */
